var Scroll = require("react-scroll")
var scroller = Scroll.scroller

exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("stara:", prevLocation)
//   console.log("nowa:", location)
  checkHash(location)
}

const checkHash = location => {
  let { hash, search } = location
  if (hash) {
    scroller.scrollTo(hash.substring(1), {
      spy: true,
      duration: 500,
      smooth: true,
      offset: -57,
      hashSpy: true,
      isDynamic: true,
    })
  }
  if (search) {
    scroller.scrollTo(search.substring(1), {
      spy: true,
      duration: 500,
      smooth: true,
      offset: -57,
      hashSpy: true,
      isDynamic: true,
    })
  }
}
